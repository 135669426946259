import './ui-elements'
import './form'
import './popup'
import './animation'

$(function () {

  Fancybox.bind("[data-fancybox]", {});
  console.log(Fancybox);

  if($("div").is(".js-product-init")){ 
    var product = new Swiper('.js-product-init', {
      spaceBetween: 20,		
      slidesPerView: 4,
      loop: true,
      navigation: {
        nextEl: '.js-product__next',
        prevEl: '.js-product__prev',
      }, 
      breakpoints: {
        992: {
          spaceBetween: 20,		
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 20,		
          slidesPerView: 2,
        },
        580: {
          spaceBetween: 20,		
          slidesPerView: 1, 
        },  
      },
    });
  }



  $('.js-nav-btn').on('click',function(){	
    var $this = $(this);
    var navBlock = $('.js-menu-wrap');
    $this.toggleClass('burger_active');
    if ($this.hasClass('burger_active')) {
        navBlock.animate({"left":"0px"});	
      } else {
        navBlock.animate({"left":"-100%"});	
      } 

  });


  if($('.home').length) {
    $(".js-menu-anchor a").click(function (e) {
      if($(window).width()<=992){
        var navBlock = $('.js-menu-wrap');
        navBlock.animate({"left":"-100%"});	
        $('.js-nav-btn').removeClass('burger_active');
      }
      
      e.preventDefault();
      var _href = $(this).attr("href").split('#').pop();	
      console.log(_href);
      $("html, body").animate({
        scrollTop: ($("#"+_href).offset().top-50) + "px"
      });
      return false;
    });
  } 

  $(window).scroll(function(){
    var aTop = 0;
    var navblock= $('header'); 
    if($(this).scrollTop()>aTop){
      navblock.addClass('fixed');
    } else{
      navblock.removeClass('fixed');
    }
  });

  $(".js-anchor").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({
      scrollTop: ($(_href).offset().top-50) + "px"
    });
    return false;
  });



  $('.js-portfolio-btn').on("click",function(e){

    e.preventDefault();

    $('.js-portfolio-btn').removeClass("active");

    $(this).addClass("active");

    let data={
      'term_id': $(this).data('term_id'), 
      'action': 'portfolio_filter',
      'count': $(this).data('count'),
    };
    console.log(data); 

    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: data, 
      error: function (jqXHR, exception) {
      },
      beforeSend : function ( xhr ) {		
      
      },
      success : function( data ){   
        console.log(data);
        if (data) {
          jQuery('.portfolio-items').html(data);        
        } else { }
      }
    });

  });


  function sliders_init() {
    $('.product-item__slider').each(function(){
      var doneslider = new Swiper( this.querySelector('.card-slider'), {
        spaceBetween: 10,
        autoHeight: true,
        // navigation: {
        //   nextEl: this.querySelector('.psm_next'),
        //   prevEl: this.querySelector('.swiper-pagination'),
        // },
        pagination: {
          el: this.querySelector('.swiper-pagination'),
        },
             loop: true,
            loopedSlides: 4
        });
    });
  }

  sliders_init();
  

  $('.faq-item__header').click(function(event){			  
    event.preventDefault();   
    $(this).parent().toggleClass("opened");
  });


    
  if (($(".product-slider").length > 0 )) {

    var doneslider = new Swiper( this.querySelector('.main-slider'), {
      spaceBetween: 0,
      autoHeight: true,
      navigation: {
        nextEl: this.querySelector('.psm_next'),
        prevEl: this.querySelector('.psm_prev'),
      },
          loop: true,
          loopedSlides: 4
      });

      $('.js-slide-to').click(function(e) {
        e.preventDefault();
        let index=$(this).data("slide");
        console.log(index);
        doneslider.slideTo(index, 300);
      });

  }





  $('.js-product-cat').click(function(e) {

    e.preventDefault(); 
    $('.js-product-cat').removeClass("active");
    $(this).addClass("active");
    let term_id= $(this).data('term_id'); 
    let blog_id= $(this).data('blog_id'); 

    let count= $(this).data('count'); 
    let data={
      'term_id': term_id, 
      'blog_id' : blog_id, 
      'action': 'car_filter',
      'count': count,
    };

    console.log(data);

    if(term_id!=0)  $('.pag').addClass("d-none"); else $('.pag').removeClass("d-none");
   
  
    $.ajax({
      type: 'POST',
      url: '/samuiwp/wp-admin/admin-ajax.php',
      data: data, 
      error: function (jqXHR, exception) {
      },
      beforeSend : function ( xhr ) {		
      
      },
      success : function( data ){   
        console.log(data);
        if (data) {
          jQuery('.js-product-items').html(data);
          sliders_init();
        
        } else { }
      }
    });

  });
  

  // const start = datepicker('.start', { id: 1 })
  // const end = datepicker('.end', { id: 1 })


  // rent form
  // $('.js-rent-form').submit(function(e) {
  //   e.preventDefault();
  //   let thisForm = $(this);
  //   let formData = new FormData($(this)[0]);


  //   $.ajax({
  //     url: '/samuiwp/wp-admin/admin-ajax.php',
  //     type: 'POST',
  //     processData: false,
  //     contentType: false,
  //     data: formData, 
  //     dataType: "json",
  //     success: function(data) {
  //       console.log(data);
  //       openPopup("thank", "", "","","");
  //       setTimeout(function(){
  //         $('.thank-popup').removeClass('is-show');
  //       },4000); 
  //     }  
  //   });

  // });

  $('.js-back').click(function(e){
    e.preventDefault();
    history.back();
  });




  $('.js-all-options').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });

  $('.js-view-all').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });
  
}); 