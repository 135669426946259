$(function () {

	// Ввод только цифр
		$('.js-number').keypress(function(e){
			let charCode = (e.which) ? e.which : e.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				return false;
			}
		});
	
	// Деактивация кнопки отправки формы при снятии галочки с чекбокса согласия
		$('body').on('change', '.js-agree', function () {
			let $this = $(this);
			let btn = $this.closest('.js-form').find('.submit');
			if ($this.is(':checked')) {
				btn.removeAttr("disabled"); 
			} else {
				btn.attr("disabled", true);
			}
		});
	
	
	// Валидация формы
		let formValidate = function() {
	
			let form = $('.js-rent-form');
	
			form.each(function(){
				let thisForm = $(this);
	
				thisForm.validate({
					messages: {
						name: thisForm.find('input[name=name]').data("errorname"), 
						phone: thisForm.find('input[name=name]').data("errorname"),
						date_from: thisForm.find('input[name=date_from]').data("errorname"),
						date_to: thisForm.find('input[name=date_to]').data("errorname")
					},
					focusInvalid: false,
					highlight: function(element) {
						$(element).closest(".form-field").addClass('form-field--error');
						thisForm.find('button').prop("disabled", true);
					},
					unhighlight: function(element) {
						$(element).closest(".form-field").removeClass('form-field--error');
						thisForm.find('button').prop("disabled", false);
					},
					submitHandler: function(form, event){		
						event.preventDefault();			
						
						
						let formData = new FormData(thisForm[0]);


						$.ajax({
							url: '/samuiwp/wp-admin/admin-ajax.php',
							type: 'POST',
							processData: false,
							contentType: false,
							data: formData, 
							dataType: "json",
							success: function(data) {
								console.log(data);
								openPopup("thank", "", "","","");
								setTimeout(function(){
									$('.thank-popup').removeClass('is-show');
								},4000); 
							}  
						});


							
	
						return false;
					}
				});
	
			});
		};
	
		formValidate();
	
		window.formValidate = formValidate;
	
		$B.on('click', 'label.error',function(){
			$(this).hide().siblings('input,textarea').focus();
	
		});
	
		$B.on('keyup', 'input',function(){
			$(this).siblings('label.error').hide();
		});
	
		$B.on('change', '.js-select', function(){
			console.log('rfd')
			$(this).closest('.form-field').removeClass('form-field--error');
		})
	
		$('.popup-thank').on('click', function(){
			$(this).removeClass('is-show');
		});
	
		
	
	});
	
	
	