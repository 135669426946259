// import 'jquery-viewport-checker';
import { TimelineLite, TweenLite, TweenMax, Linear, gsap } from "gsap";
// import ScrollMagic from "../libs/ScrollMagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";

$(function () {

	// gsap.registerPlugin(ScrollTrigger); 
	// gsap.config({nullTargetWarn:false});


	// gsap.to('.banner-item__bg', {
	// 	scrollTrigger: {
	// 		trigger: 'div[data-anchor=box_1]',
	// 		// start: 'bottom',
	// 		// markers: false,
	// 		// scrub: 1,
	// 		// start: '-=100',
	// 	},
	// 	y: -50,
	// })


	// const parallaxContainer = document.querySelector('.wrapper')
	// const parallaxItems = document.querySelectorAll('.parallax') 
	// const fixer = 0.003 
	// document.addEventListener('mousemove', function (event) {
	// 	if(!parallaxContainer){return}
	// 	const pageX =
	// 		event.pageX - parallaxContainer.getBoundingClientRect().width * 0.5
 
	// 	const pageY =
	// 		event.pageY - parallaxContainer.getBoundingClientRect().height * 0.5
 
	// 	for (let i = 0; i < parallaxItems.length; i++) {
	// 		const item = parallaxItems[i]
	// 		const speedX = item.getAttribute('data-speed-x')
	// 		const speedY = item.getAttribute('data-speed-y')
	// 		const speedZ = item.getAttribute('data-speed-z')
	// 		TweenLite.set(item, {
	// 			x: (item.offsetLeft + pageX * speedX) * fixer,
	// 			y: (item.offsetTop + pageY * speedY) * fixer,
	// 			z: (item.offsetTop + pageY * speedZ) * 0.02,
	// 		})
	// 	}
	// })

  var doAnimations = function() {
   
    var offset = $(window).scrollTop(),

    $animatables = $('.animatable');

    if ($animatables.length == 0) {
      $(window).off('scroll', doAnimations);
    }
    
		$animatables.each(function(i) {
       var $animatable = $(this);
			if (($animatable.offset().top) < offset) {
        $animatable.removeClass('animatable').addClass('animated');
			} else{
				$animatable.removeClass('animated').addClass('animatable');
			}
    });

	};



  
	 $(window).on('scroll', doAnimations);


});
